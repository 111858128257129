<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<!--
  Page to demonstrate the MSS pattern for saving search parameters and search results.
 -->
<template>
	<div>
		<h1 class="mt-4">Saving Search Results Demo</h1>
		<b-row>
			<b-col cols="6">
				<b-form @submit.prevent="getClaims">
					<b-form-group label="participant (01)" label-for="participant">
						<b-form-input id="participant" v-model="searchParameters.participant"></b-form-input>
					</b-form-group>
					<b-form-group label="claimType (03)" label-for="claimType">
						<b-form-input id="claimType" v-model="searchParameters.claimType"></b-form-input>
					</b-form-group>
					<b-form-group label="fromDate (20190101)" label-for="fromDate">
						<b-form-input id="fromDate" v-model="searchParameters.fromDate"></b-form-input>
					</b-form-group>
					<b-form-group label="toDate (20220327)" label-for="toDate">
						<b-form-input id="toDate" v-model="searchParameters.toDate"></b-form-input>
					</b-form-group>

					<button type="submit" class="btn btn-primary">Submit</button>
				</b-form>
			</b-col>
		</b-row>

		<b-alert :show="isEmptyResults" class="mt-3" variant="danger">No results found</b-alert>

		<b-row class="mt-4">
			<b-col>
				<h2 class="mt-4">Results</h2>
				<p>
					Note: The "To other page" hyperlink in the "link" column is to simulate going to a details
					page. It's hard coded to navigate to the My Account page.
				</p>
				<b-table striped hover :fields="fields" :items="claims">
					<template #cell(link)>
						<router-link :to="{ name: 'manage-your-account' }" role="button">
							To other page
						</router-link>
					</template>
				</b-table>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { BForm, BRow, BCol, BTable, BFormInput, BFormGroup, BAlert } from 'bootstrap-vue';
import MyClaim from '@/models/demo/SavingSearchHistoryDemoModel.js';

export default {
	name: 'SavingSearchHistoryDemo',
	components: {
		BForm,
		BRow,
		BCol,
		BTable,
		BFormInput,
		BFormGroup,
		BAlert
	},
	data() {
		return {
			fields: ['link', 'serviceDate', 'benefitDesc', 'paidAmount'],
			claims: [], // Reactive search results
			searchParameters: {}, // Reactive search parameters
			isEmptyResults: false
		};
	},

	computed: {
		memberEmail() {
			return sessionStorage.getItem('email');
		},

		apiToken() {
			return sessionStorage.getItem('apiToken');
		}
	},

	/**
	 * When page loads (first time or when member returns), get saved search parameters and/or results
	 */
	async created() {
		this.searchParameters = (await MyClaim.getSavedSearchParameters(this.memberEmail)) || {};
		this.claims = (await MyClaim.getSavedSearchResults(this.memberEmail)) || [];
	},

	methods: {
		/**
		 * When member clicks "search" we get new data from a service call.
		 */
		async getClaims() {
			this.claims = await MyClaim.getClaimHistory(
				this.memberEmail,
				this.apiToken,
				this.$root.$i18n.locale,
				this.searchParameters
			);

			// Check if any claims found
			this.isEmptyResults = !this.claims?.length || false;
		}
	}
};
</script>
